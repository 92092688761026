<script>
import { resultsRoute } from '@src/router'
import { createTaskMutation } from '@src/mutations'
import CreateAnalysisStepper from '@comp/createAnalysis/CreateAnalysisStepper.vue'
import MapView from '@comp/createAnalysis/MapView.vue'
import Indicator from '@src/utils/indicators'
import { getLineStringFromGeojson } from '@src/queries'
import { useIndexStore } from '@src/store'

export default {
  name: 'CreateAnalysis',
  components: {
    CreateAnalysisStepper,
    MapView,
  },
  data () {
    return {
      currentStep: 1,
      bbox: null,
      lines: [],
      geojsonLines: {},
      selectedIndicators: [...Indicator].map(indicator => indicator.name),
      outputFiles: {
        standaloneMaps: false,
        rawData: false,
      },
      runningGeojsonValidation: false,
      geojsonValidationError: false,
    }
  },
  mounted () {
    const store = useIndexStore()
    store.changeRoute(this.$options.name)
  },
  methods: {
    setBBox (bbox) {
      this.bbox = bbox // could be null
    },
    setLines (lines) {
      if (lines) {
        this.lines = lines.features // could be []
      }
    },
    setSelectedIndicators (indicators) {
      this.selectedIndicators = indicators.map(indicator => indicator.name)
    },
    setOutputFiles (outputFiles) {
      this.outputFiles = outputFiles
    },
    async validateGeojsonFile (file) {
      if (file) {
        this.runningGeojsonValidation = true
        this.geojsonValidationError = false
        await this.$apollo.query({
          query: getLineStringFromGeojson,
          variables: {
            geojsonFile: file,
          },
        }).then(({ data, errors }) => {
          if (errors) {
            this.geojsonValidationError = true
          } else {
            this.geojsonLines = data.getLineStringFromGeojson.geojson
          }
          this.runningGeojsonValidation = false
        })
      }
    },
    async createTask () {
      const multiline = {
        lines: this.lines.map(line => line.geometry.coordinates.map(point => (
          { longitude: point[0], latitude: point[1] }),
        )),
      }
      await this.$apollo.mutate({
        mutation: createTaskMutation,
        variables: {
          west: this.bbox[0],
          south: this.bbox[1],
          east: this.bbox[2],
          north: this.bbox[3],
          multiline,
          indicators: this.selectedIndicators,
          geotiff: this.outputFiles.rawData,
          standaloneMaps: this.outputFiles.standaloneMaps,
        },
      }).then(({ data: { createTask } }) => {
        console.assert(createTask)
        this.$router.push({ name: resultsRoute.name })
      })
    },
    goToStep (step) {
      this.currentStep = step
    },
  },
}
</script>
<template>
  <section class="view create-analysis">
    <div class="analysis-stepper">
      <CreateAnalysisStepper
        :current-step="currentStep"
        :bbox="bbox"
        :lines="lines"
        :selected-indicators="selectedIndicators"
        :running-geojson-validation="runningGeojsonValidation"
        :geojson-validation-error="geojsonValidationError"
        @selectedIndicatorsUpdated="setSelectedIndicators"
        @outputFilesUpdated="setOutputFiles"
        @goToStep="goToStep"
        @createTask="createTask"
        @validateGeojsonFile="validateGeojsonFile"
      />
    </div>
    <MapView
      :current-step="currentStep"
      :geojson-lines="geojsonLines"
      :loading="runningGeojsonValidation"
      @bboxUpdated="setBBox"
      @linesUpdated="setLines"
    />
  </section>
</template>
<style lang="scss" scoped>
.create-analysis {
  flex-direction: row;
  gap: 20px;
  max-height: calc(100vh - 50px);
}
.analysis-stepper {
  flex-grow: 1;
  max-width: 45%;
}
</style>
