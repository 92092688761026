<script>
import InfoTooltip from '@comp/InfoTooltip.vue'
import { STATUS_COLORS } from '@src/constants'

export default {
  name: 'StatusesInfoTooltip',
  components: {
    InfoTooltip,
  },
  data () {
    return {
      colors: STATUS_COLORS,
    }
  },
}
</script>
<template>
  <InfoTooltip
    max-width="600px"
  >
    <div>
      <span>{{ $gettext('Statuses describing what is being done on your task right now') }}</span>
      <ul>
        <li>
          <v-chip
            :color="colors.GREEN"
            variant="elevated"
            size="x-small"
          >
            {{ $gettext('SUCCESS') }}
          </v-chip>
          {{ $gettext(':') }} {{ $gettext('your task is finished, the results are available') }}
        </li>
        <li>
          <v-chip
            :color="colors.RED"
            variant="elevated"
            size="x-small"
          >
            {{ $gettext('FAILURE') }}
          </v-chip>
          <!-- eslint-disable-next-line max-len -->
          {{ $gettext(':') }} {{ $gettext('something went wrong on our side. Please retry and if it doesn\'t work again, contact us so we can fix that.') }}
        </li>
        <li>
          <v-chip
            :color="colors.ORANGE"
            variant="elevated"
            size="x-small"
          >
            {{ $gettext('RETRY') }}
          </v-chip>
          {{ $gettext(':') }} {{ $gettext('your task failed, but we have launched it again for you') }}
        </li>
        <li>
          <v-chip
            :color="colors.GREY"
            variant="elevated"
            size="x-small"
          >
            {{ $gettext('REVOKE') }}
          </v-chip>
          {{ $gettext(':') }} {{ $gettext('you cancelled this task') }}
        </li>
      </ul>
    </div>
  </InfoTooltip>
</template>
<style lang="scss" scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 1ch;
}
</style>
