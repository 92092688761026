<script>
import Welcome from '@comp/Welcome.vue'
import WelcomeButtons from '@comp/WelcomeButtons.vue'
import { useIndexStore } from '@src/store'

export default {
  name: 'Home',
  components: {
    Welcome,
    WelcomeButtons,
  },
  async mounted () {
    const store = useIndexStore()
    store.changeRoute(this.$options.name)
  },
}
</script>
<template>
  <section class="view home">
    <Welcome />
    <WelcomeButtons />
  </section>
</template>
<style lang="scss" scoped>
.home {
  max-height: calc(100vh - 50px);
}
</style>
