import createEnum from '@src/utils/enum'

const $gettext = s => s // for easygettext to peek translations

/* eslint-disable max-len */
const Indicator = createEnum(
  {
    name: 'hwdays',
    value: {
      label: $gettext('Heat wave number of days'),
      selected: true,
      tooltipText: $gettext('Heat Wave Days (days): Number of heat wave days, based on climatological EURO-CORDEX definition, averaged over 30 years and 8 EURO-CORDEX models'),
    },
  },
  {
    name: 'tas',
    value: {
      label: $gettext('Mean temperatures'),
      selected: true,
      tooltipText: $gettext('Mean Temperatures (⁰C): Yearly average of daily mean temperatures, averaged over 30 years and 8 EURO-CORDEX models'),
    },
  },
  {
    name: 'tasmin',
    value: {
      label: $gettext('Minimum temperatures'),
      selected: true,
      tooltipText: $gettext('Minimum Temperatures (⁰C): Yearly P1 percentile of daily minimal temperatures, averaged over 30 years and 8 EURO-CORDEX models'),
    },
  },
  {
    name: 'tasmax',
    value: {
      label: $gettext('Maximum temperatures'),
      selected: true,
      tooltipText: $gettext('Maximum Temperatures (⁰C): Yearly P99 percentile of daily maximum temperatures, averaged over 30 years and 8 EURO-CORDEX models'),
    },
  },
  {
    name: 'pmean',
    value: {
      label: $gettext('Mean precipitations'),
      selected: true,
      tooltipText: $gettext('Mean Precipitation (mm): Yearly mean of daily precipitations, averaged over 20 years and 9 EURO-CORDEX models'),
    },
  },
  {
    name: 'pmax',
    value: {
      label: $gettext('Maximum daily precipitations'),
      selected: true,
      tooltipText: $gettext('Maximum Daily Precipitation (mm): Yearly maximum of daily precipitations, averaged over 20 years and 8 EURO-CORDEX models'),
    },
  },
  {
    name: 'wmax',
    value: {
      label: $gettext('Mean wind velocity'),
      selected: true,
      tooltipText: $gettext('Mean Wind Velocity (m/s): Yearly maximum of average monthly wind speed, averaged over 20 years and 10 EURO-CORDEX models'),
    },
  },
)
/* eslint-enable max-len */
export default Indicator
