<script>
export default {
  name: 'OutputFilesPanel',
  emits: [
    'outputFilesUpdated',
  ],
  data () {
    return {
      comparativeGrids: true,
      standaloneMaps: false,
      rawData: false,
    }
  },
  computed: {
    outputFiles () {
      return {
        standaloneMaps: this.standaloneMaps,
        rawData: this.rawData,
      }
    },
  },
  watch: {
    outputFiles: {
      handler () {
        this.$emit('outputFilesUpdated', this.outputFiles)
      },
      deep: true,
    },
  },
}
</script>
<template>
  <div class="data-panel scenarios">
    <div class="data-panel-title">
      {{ $gettext('Output files') }}
    </div>
    <div class="data-panel-content">
      <v-checkbox
        v-model="comparativeGrids"
        :label="$gettext('Comparative Grids of maps')"
        :hide-details="true"
        :readonly="true"
        color="mediumgrey"
        density="compact"
      />
      <v-checkbox
        v-model="standaloneMaps"
        :label="$gettext('Standalone maps')"
        :hide-details="true"
        color="primary"
        density="compact"
      />
      <v-checkbox
        v-model="rawData"
        :label="$gettext('Raw data (GeoTIFF format)')"
        :hide-details="true"
        color="primary"
        density="compact"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>

:deep(.v-label) {
  margin-left: 0.5ch;
}
</style>
