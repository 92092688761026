<script>
export default {
  name: 'ScenarioItem',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
}
</script>
<template>
  <div class="scenario-item">
    <div class="scenario-title">
      {{ name }}
    </div>
    <div class="scenario-legend">
      <slot />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.scenario-item {
  display: grid;
  grid-template-areas: "scenario-title scenario-legend";
  grid-template-columns: 100px 1fr;
  gap: 2rem;
  align-items: center;
}
.scenario-title {
  grid-area: 'scenario-title';
  font-weight: bold;
}
.scenario-legend {
  grid-area: 'scenario-legend';
  font-size: 0.8rem;
}
</style>
