import { createRouter, createWebHistory } from 'vue-router'
import { checkAuth } from '@src/utils/auth'
import { useIndexStore } from '@src/store'

// Pages
import Home from '@page/Home.vue'
import Login from '@page/Login.vue'
import Fail from '@page/Fail.vue'
import User from '@page/User.vue'
import CreateAnalysis from '@page/CreateAnalysis.vue'
import ResultsDashboard from '@page/ResultsDashboard.vue'

// only used to force to see translation to vue-gettext
const $gettext = s => s

// Auth pages start
const loginPath = '/login'
export const ifAuthenticated = async (_to, _from, next) => {
  const user = await checkAuth()
  if (user) {
    useIndexStore().changeUser(user)
    next()
  } else {
    next(loginPath)
  }
}
export const loginRoute = {
  path: loginPath,
  name: 'Login',
  component: Login,
}
export const failRoute = {
  path: '/fail',
  name: 'Fail',
  component: Fail,
}
export const userRoute = {
  path: '/user',
  name: 'User',
  component: User,
  beforeEnter: ifAuthenticated,
}
export const disconnectRoute = { /* no path, no component, just a link in the menu */
  name: 'Disconnect',
  icon: 'fas fa-sign-out-alt',
  title: $gettext('Logout'),
}
// Auth pages end
export const homeRoute = {
  path: '/',
  name: 'Home',
  component: Home,
  icon: 'fas fa-house',
  title: $gettext('Home'),
  beforeEnter: ifAuthenticated,
}
export const createAnalysisRoute = {
  path: '/create-analysis',
  name: 'CreateAnalysis',
  component: CreateAnalysis,
  icon: 'fas fa-search-location',
  title: $gettext('Create analysis'),
  beforeEnter: ifAuthenticated,
}
export const resultsRoute = {
  path: '/results',
  name: 'ResultsDashboard',
  component: ResultsDashboard,
  icon: 'fas fa-layer-group',
  title: $gettext('Results dashboard'),
  beforeEnter: ifAuthenticated,
}
export const routes = [
  homeRoute,
  loginRoute,
  failRoute,
  userRoute,
  createAnalysisRoute,
  resultsRoute,
]
export const fullscreenRoutes = [
  loginRoute,
  failRoute,
]
export const navigationRoutes = [
  homeRoute,
  createAnalysisRoute,
  resultsRoute,
  disconnectRoute,
]
export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})
