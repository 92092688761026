<script>
import { STATUS_COLORS } from '@src/constants'
import { formatDateAndTime } from '@src/utils/date'
import InfoTooltip from '@comp/InfoTooltip.vue'
import StatusesInfoTooltip from '@comp/resultsDashboard/StatusesInfoTooltip.vue'

// eslint-disable-next-line no-unused-vars
function forTranslations () {
  const $gettext = () => {}
  $gettext('starting…')
  $gettext('background map')
  $gettext('geotiffs for')
  $gettext('charts for')
  $gettext('grid chart for')
}
const regexpWithIndicator = /^(.+) ('.+' \([0-9]+%\))$/
const regexpWithoutIndicator = /(.+) (\([0-9]+%\))$/

export default {
  name: 'TaskTable',
  components: {
    InfoTooltip,
    StatusesInfoTooltip,
  },
  props: {
    tasks: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'deleteTask',
  ],
  computed: {
    headers () {
      return [
        { title: this.$gettext('Analysis date and time'), key: 'creationDatetime', sortable: false, width: '40%' },
        { title: this.$gettext('Status'), key: 'status', sortable: false, width: '20%' },
        { title: this.$gettext('Expiration date and time'), key: 'expirationDatetime', sortable: false, width: '25%' },
        { title: this.$gettext('Actions'), key: 'actions', sortable: false, width: '15%' },
      ]
    },
  },
  methods: {
    formatDateAndTime,
    isExpirationDateTimeHeader (key) {
      return key === 'expirationDatetime'
    },
    isStatusHeader (key) {
      return key === 'status'
    },
    isPendingStatus (status) {
      return status === 'PENDING'
    },
    getStatusColor (status) {
      switch (status) {
        case 'SUCCESS':
          return STATUS_COLORS.GREEN
        case 'RETRY':
          return STATUS_COLORS.ORANGE
        case 'FAILURE':
          return STATUS_COLORS.RED
        case 'REVOKE':
        default:
          return STATUS_COLORS.GREY
      }
    },
    canDownloadTask (task) {
      return task.status === 'SUCCESS' && !!task.resultUrl
    },
    canCancelTask (task) {
      switch (task.status) {
        case 'PENDING':
        case 'STARTED':
        case 'RETRY':
        case 'SUCCESS':
        case 'FAILURE':
          return true
        case 'REVOKE':
        default:
          return false
      }
    },
    cancelTask (task) {
      this.$emit('deleteTask', task)
    },
    infoTranslate (info, gettext) {
      // Reg expressions hereafter seem to be useless... Can't be tested
      const m = regexpWithIndicator.exec(info) || regexpWithoutIndicator.exec(info)
      return m ? [gettext(m[1]), m[2]].join(' ') : gettext(info)
    },
    downloadResults (task) {
      const link = document.createElement('a')
      link.href = task.resultUrl
      link.setAttribute('download', 'export.zip')
      document.body.appendChild(link)
      link.click()
    },
  },
}
</script>
<template>
  <section
    class="card data-table-container"
    :class="[ tasks.length === 0 ? 'empty-data-table' : '']"
  >
    <v-data-table-virtual
      :headers="headers"
      :items="tasks"
    >
      <template v-slot:headers="{ columns }">
        <tr>
          <template
            v-for="column in columns"
            :key="column.key"
          >
            <th>
              {{ column.title }}
              <InfoTooltip
                v-if="isExpirationDateTimeHeader(column.key)"
                max-width="300px"
              >
                <div>
                  {{ $gettext('Date at witch the analysis and its results will be deleted from ClimatePlus.') }}
                </div>
              </InfoTooltip>
              <StatusesInfoTooltip
                v-if="isStatusHeader(column.key)"
              />
            </th>
          </template>
        </tr>
      </template>
      <template v-slot:[`item.creationDatetime`]="{ item: task }">
        {{ formatDateAndTime(task.creationDatetime) }}
      </template>
      <template v-slot:[`item.status`]="{ item: task }">
        <div
          v-if="isPendingStatus(task.status)"
          class="loading-status"
        >
          <v-progress-circular
            color="primary"
            indeterminate
            width="6"
            size="24"
          />
          <span>
            {{ infoTranslate(task.pendingInfo || 'Starting…', $gettext) }}
          </span>
        </div>
        <v-chip
          v-else
          :color="getStatusColor(task.status)"
        >
          {{ $gettext(task.status) }}
        </v-chip>
      </template>
      <template v-slot:[`item.expirationDatetime`]="{ item: task }">
        {{ formatDateAndTime(task.expirationDatetime) }}
      </template>
      <template v-slot:[`item.actions`]="{ item: task }">
        <v-btn
          :disabled="!canDownloadTask(task)"
          icon="fas fa-download"
          color="primary"
          size="small"
          variant="text"
          class="download-button"
          @click="downloadResults(task)"
        />
        <v-btn
          v-if="canCancelTask(task)"
          icon="fas fa-trash-alt"
          color="secondary"
          size="small"
          variant="text"
          @click="cancelTask(task)"
        />
      </template>
    </v-data-table-virtual>
  </section>
</template>
<style lang="scss" scoped>
.data-table-container {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  max-height: calc(100vh - 50px - 60px - 40px - 40px);
}
.v-table {
  max-height: inherit;
}
/* stylelint-disable-next-line selector-class-pattern */
:deep(.v-table .v-table__wrapper > table > thead > tr > th) {
  background-color: $secondary;
  color: $white;
  font-weight: bold;
  font-size: 14px;
}
/* stylelint-disable-next-line selector-class-pattern */
.empty-data-table :deep(.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td) {
  color: rgba($white, 0.38)
}
:deep(.v-table .v-icon) {
  padding-top: 2px;
}
.download-button {
  margin-right: 1ch;
}
div.loading-status {
  display: flex;
  flex-direction: row;
  gap: 1ch;
  vertical-align: middle;
}
.v-chip {
  width: 14ch;
  justify-content: center;
}
</style>
