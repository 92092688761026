<script>
import IndicatorGroup from './IndicatorGroup.vue'
import Indicator from '@src/utils/indicators'

export default {
  name: 'IndicatorsPanel',
  components: {
    IndicatorGroup,
  },
  emits: [
    'selectedIndicatorsUpdated',
  ],
  data () {
    return {
      tempIndicators: [Indicator.tas, Indicator.tasmin, Indicator.tasmax, Indicator.hwdays].map(
        indicator => ({
          name: indicator.name,
          label: indicator.value.label,
          value: indicator.value.selected,
          tooltipText: indicator.value.tooltipText,
        }),
      ),
      windIndicators: [Indicator.pmean, Indicator.pmax, Indicator.wmax].map(
        indicator => ({
          name: indicator.name,
          label: indicator.value.label,
          value: indicator.value.selected,
          tooltipText: indicator.value.tooltipText,
        }),
      ),
    }
  },
  computed: {
    selectedIndicators () {
      return [...this.tempIndicators, ...this.windIndicators].filter(
        indicatorInfo => indicatorInfo.value,
      ).map(
        indicatorInfo => Indicator.fromName(indicatorInfo.name),
      )
    },
  },
  watch: {
    selectedIndicators () {
      this.$emit('selectedIndicatorsUpdated', this.selectedIndicators)
    },
  },
}
</script>
<template>
  <div class="data-panel">
    <div class="data-panel-title">
      {{ $gettext('Indicators') }}
    </div>
    <div class="data-panel-content">
      <IndicatorGroup
        :name="$gettext('Temperatures')"
        icon="fas fa-temperature-low"
        :indicators="tempIndicators"
      >
        <template #horizons>
          <span>{{ $gettext('Reference 1976-2005') }}</span>
          <span>{{ $gettext('Forecast 2021-2050') }}</span>
          <span>{{ $gettext('Forecast 2041-2070') }}</span>
          <span>{{ $gettext('Forecast 2071-2100') }}</span>
        </template>
      </IndicatorGroup>
      <IndicatorGroup
        :name="$gettext('Wind and precipitations')"
        icon="fas fa-cloud-showers-heavy"
        :indicators="windIndicators"
      >
        <template #horizons>
          <span>{{ $gettext('Reference 1980-1999') }}</span>
          <span>{{ $gettext('Forecast 2021-2050') }}</span>
          <span>{{ $gettext('Forecast 2041-2070') }}</span>
          <span>{{ $gettext('Forecast 2071-2100') }}</span>
        </template>
      </IndicatorGroup>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.data-panel-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
</style>
