<script>
import ResultsDashboardHeader from '@comp/resultsDashboard/ResultsDashboardHeader.vue'
import TaskTable from '@comp/resultsDashboard/TaskTable.vue'
import { RESULTS_REFRESH_MS } from '@src/constants'
import { allTasksQuery } from '@src/queries'
import { deleteTaskMutation } from '@src/mutations'
import { useIndexStore } from '@src/store'

export default {
  name: 'ResultsDashboard',
  components: {
    ResultsDashboardHeader,
    TaskTable,
  },
  data () {
    return {
      tasks: [],
      intervalID: null,
    }
  },
  async mounted () {
    const store = useIndexStore()
    store.changeRoute(this.$options.name)
    await this.setData()
    this.intervalID = setInterval(this.setData, RESULTS_REFRESH_MS)
  },
  unmounted () {
    if (this.intervalID) {
      clearInterval(this.intervalID)
      this.intervalID = null
    }
  },
  methods: {
    async setData () {
      return this.$apollo.query({
        query: allTasksQuery,
        variables: {},
      }).then(({ data: { allTasks } }) => {
        this.tasks = allTasks
      })
    },
    async deleteTask (task) {
      return this.$apollo.mutate({
        mutation: deleteTaskMutation,
        variables: {
          id: task.id,
        },
      }).then(() => {
        return this.setData()
      })
    },
  },
}
</script>
<template>
  <section class="view results">
    <ResultsDashboardHeader />
    <TaskTable
      :tasks="tasks"
      @deleteTask="deleteTask"
    />
  </section>
</template>
<style lang="scss" scoped>
.results {
  gap: 20px;
  flex-direction: column;
}
</style>
