<script>
import ScenarioItem from './ScenarioItem.vue'
import InfoTooltip from '@comp/InfoTooltip.vue'

// eslint-disable-next-line no-unused-vars
function forTranslations () {
  const $gettext = () => {}
  $gettext('Moderate') // scenario
  $gettext('Highest') // scenario
}

export default {
  name: 'ScenariosPanel',
  components: {
    ScenarioItem,
    InfoTooltip,
  },
  methods: {
    getEmissionScenarioLabel (scenarioType) {
      const tmpl = this.$gettext('%s emissions scenario (IPCC)')
      const gettext = this.$gettext
      return tmpl.replace('%s', `<strong>${gettext(scenarioType)}</strong>`)
    },
  },
}
</script>
<template>
  <div class="data-panel scenarios">
    <div class="data-panel-title">
      {{ $gettext('Scenarios') }}
      <InfoTooltip
        tooltip-position="end"
      >
        <!-- eslint-disable max-len -->
        <div class="tooltip-content">
          <div>{{ $gettext('A RCP stands for a Representative Concentration Pathway: it is a greenhouse gas (GHG) atmosphere concentration trajectory adopted by IPCC the Intergovernmental Panel (of scientific experts) on Climate Change.') }}</div>
          <div>{{ $gettext('Numbers 4.5 and 8.5 refer to scenarios for the 2100 radiative forcing value (W/m²) due to the GHG in the atmosphere.') }}</div>
          <div>{{ $gettext('RCP 4.5 would mean an average temperature rise between 2 and 3 °C by 2100 whereas RCP 8.5 over 4°C.') }}</div>
        </div>
        <!-- eslint-enable max-len -->
      </InfoTooltip>
    </div>
    <div class="data-panel-content">
      <ScenarioItem name="RCP 4.5">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="getEmissionScenarioLabel('Moderate')" />
      </ScenarioItem>
      <ScenarioItem name="RCP 8.5">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="getEmissionScenarioLabel('Highest')" />
      </ScenarioItem>
    </div>
  </div>
</template>
<style scoped>
.tooltip-content {
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  gap: 0.5rem;
}
</style>
