<script>
import { createAnalysisRoute, resultsRoute } from '@src/router'
import LargeButtonWithArrow from '@comp/LargeButtonWithArrow.vue'

export default {
  name: 'WelcomeButtons',
  components: {
    LargeButtonWithArrow,
  },
  methods: {
    goToCreateAnalysis () {
      this.$router.push({ name: createAnalysisRoute.name })
    },
    goToResults () {
      this.$router.push({ name: resultsRoute.name })
    },
  },
}
</script>
<template>
  <section class="card welcome-navigation">
    <LargeButtonWithArrow
      :callback="goToCreateAnalysis"
      :icon="'fas fa-search-location'"
      :text="$gettext('Launch a new analysis')"
    />
    <LargeButtonWithArrow
      :callback="goToResults"
      :icon="'fas fa-layer-group'"
      :text="$gettext('See the previous analysis')"
    />
  </section>
</template>
<style lang="scss" scoped>
.welcome-navigation {
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-evenly;
  align-items: center;
}

@media (width <= 1000px) {
  .welcome-navigation {
    flex-direction: column;
    width: 250px;
  }
}
</style>
