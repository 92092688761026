<script>
export default {
  name: 'LargeButtonWithArrow',
  props: {
    callback: {
      type: Function,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
}
</script>
<template>
  <v-btn
    class="button"
    :prepend-icon="icon"
    append-icon="fas fa-arrow-right"
    width="237px"
    height="278px"
    @click="callback"
  >
    {{ text }}
  </v-btn>
</template>
<style lang="scss" scoped>
.button {
  display: flex;
  flex-direction: column;
  text-transform: none;
  padding: 1em;
  row-gap: 1em;
  background-color: $grey-ultralight;
}
:deep(.v-btn__prepend) { /* stylelint-disable-line selector-class-pattern */
  flex-grow: 1;
  align-items: flex-end;
}
:deep(.v-btn__content) { /* stylelint-disable-line selector-class-pattern */
  flex-grow: 1;
  align-items: flex-start;
  letter-spacing: normal;
  white-space: normal;
  max-width: 15ch;
  font-size: 20px;
}
:deep(.v-btn__prepend .v-icon) { /* stylelint-disable-line selector-class-pattern */
  font-size: 64px;
  color: $secondary;
}
:deep(.v-btn__append) { /* stylelint-disable-line selector-class-pattern */
  flex-basis: 0;
  align-self: flex-end;
  align-items: flex-end;
}
</style>
