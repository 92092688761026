<script>
import IndicatorsPanel from './IndicatorsPanel.vue'
import ScenariosPanel from './ScenariosPanel.vue'
import OutputFilesPanel from './OutputFilesPanel.vue'

export default {
  name: 'Step3LaunchAnalysisContent',
  components: {
    IndicatorsPanel,
    ScenariosPanel,
    OutputFilesPanel,
  },
  emits: [
    'selectedIndicatorsUpdated',
    'outputFilesUpdated',
  ],
  methods: {
    selectedIndicatorsUpdated (indicators) {
      this.$emit('selectedIndicatorsUpdated', indicators)
    },
    outputFilesUpdated (outputFiles) {
      this.$emit('outputFilesUpdated', outputFiles)
    },
  },
}
</script>
<template>
  <section class="step-content">
    <IndicatorsPanel
      @selectedIndicatorsUpdated="selectedIndicatorsUpdated"
    />
    <ScenariosPanel />
    <OutputFilesPanel
      @outputFilesUpdated="outputFilesUpdated"
    />
  </section>
</template>
<style lang="scss">
.data-panel {
  background-color: $grey-ultralight;
  flex-grow: 1;
  flex-shrink: 1;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0;
}
.data-panel-title {
  align-items: baseline;
  display: inline-flex;
  gap: 0.5ch;
  font-weight: bold;
  padding: 10px 30px;
  border-bottom-style: solid;
  border-color: $white;
}
.data-panel-title > i {
 vertical-align: sub;
}
.data-panel-content {
  padding: 12px 30px;
}
</style>
