<script>
import Stepper from '@comp/stepper/Stepper.vue'
import Step1ZoneContent from './zone/Step1ZoneContent.vue'
import Step2LineContent from './line/Step2LineContent.vue'
import Step3LaunchAnalysisContent from './launchAnalysis/Step3LaunchAnalysisContent.vue'
import { point, distance } from '@turf/turf'

const MAX_WIDTH_KM = 1000
const MAX_HEIGHT_KM = 1000

export default {
  name: 'CreateAnalysisStepper',
  components: {
    Stepper,
    Step1ZoneContent,
    Step2LineContent,
    Step3LaunchAnalysisContent,
  },
  props: {
    bbox: {
      type: Array, // Long, Lat, Long, Lat
      default: null,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    lines: {
      type: Array,
      default: () => [],
    },
    selectedIndicators: {
      type: Array,
      required: true,
    },
    runningGeojsonValidation: {
      type: Boolean,
      default: false,
    },
    geojsonValidationError: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'selectedIndicatorsUpdated',
    'outputFilesUpdated',
    'goToStep',
    'createTask',
    'validateGeojsonFile',
  ],
  computed: {
    selectedZoneIsIncorrect () {
      if (this.bbox) {
        const [lon1, lat1, lon2, lat2] = this.bbox
        const meanLongitude = (lon1 + lon2) / 2
        const meanLatitude = (lat1 + lat2) / 2
        return !(
          this.isDistanceWithin(meanLongitude, lat1, meanLongitude, lat2, MAX_WIDTH_KM) &&
          this.isDistanceWithin(lon1, meanLatitude, lon2, meanLatitude, MAX_HEIGHT_KM)
        )
      } else {
        return false
      }
    },
    canGoToStep2 () {
      return !!(this.bbox && !this.selectedZoneIsIncorrect)
    },
    isNextStepMessage () {
      if (this.lines) {
        return !!(this.lines.length)
      }
      return false
    },
    analysisCreationDisabled () {
      return this.selectedIndicators.length === 0
    },
  },
  methods: {
    isDistanceWithin (lon1, lat1, lon2, lat2, distanceKm) {
      const pointA = point([lon1, lat1])
      const pointB = point([lon2, lat2])
      const lineDistanceKm = distance(pointA, pointB, 'kilometers')
      return lineDistanceKm <= distanceKm
    },
    selectedIndicatorsUpdated (indicators) {
      this.$emit('selectedIndicatorsUpdated', indicators)
    },
    outputFilesUpdated (outputFiles) {
      this.$emit('outputFilesUpdated', outputFiles)
    },
    goToStep (step) {
      this.$emit('goToStep', step)
    },
    createTask () {
      this.$emit('createTask')
    },
    validateGeojsonFile (file) {
      this.$emit('validateGeojsonFile', file)
    },
  },
}
</script>
<template>
  <Stepper
    :current-step="currentStep"
    :max-steps="3"
    class="card"
  >
    <!-- STEP 1 - ZONE -->
    <template #header-1>
      {{ $gettext('Choose the area you want to analyze') }}
    </template>
    <template #content-1>
      <Step1ZoneContent
        :selected-zone-is-incorrect="selectedZoneIsIncorrect"
      />
    </template>
    <template #footer-1>
      <v-btn
        color="primary"
        :disabled="!canGoToStep2"
        prepend-icon="fas fa-arrow-right"
        @click="goToStep(2)"
      >
        {{ $gettext('Next step') }}
      </v-btn>
    </template>
    <!-- STEP 2 - LINE -->
    <template #header-2>
      {{ $gettext('Add lines (optional)') }}
    </template>
    <template #content-2>
      <Step2LineContent
        :running-geojson-validation="runningGeojsonValidation"
        :geojson-validation-error="geojsonValidationError"
        @validateGeojsonFile="validateGeojsonFile"
      />
    </template>
    <template #footer-2>
      <v-btn
        color="primary"
        prepend-icon="fas fa-arrow-right"
        @click="goToStep(3)"
      >
        <span
          v-if="isNextStepMessage"
        >
          {{ $gettext('Next step') }}
        </span>
        <span v-else>
          {{ $gettext('Skip step') }}
        </span>
      </v-btn>
      <v-btn
        prepend-icon="fas fa-arrow-left"
        @click="goToStep(1)"
      >
        {{ $gettext('Previous') }}
      </v-btn>
    </template>
    <!-- STEP 3 - LAUNCH -->
    <template #header-3>
      {{ $gettext('Launch your analysis') }}
    </template>
    <template #content-3>
      <Step3LaunchAnalysisContent
        @selectedIndicatorsUpdated="selectedIndicatorsUpdated"
        @outputFilesUpdated="outputFilesUpdated"
      />
    </template>
    <template #footer-3>
      <v-btn
        color="primary"
        :disabled="analysisCreationDisabled"
        prepend-icon="fas fa-globe"
        @click="createTask"
      >
        {{ $gettext('Launch the analysis') }}
      </v-btn>
      <v-btn
        prepend-icon="fas fa-arrow-left"
        @click="goToStep(2)"
      >
        {{ $gettext('Previous') }}
      </v-btn>
    </template>
  </Stepper>
</template>
<style lang="scss">
.indications {
  background-color: $grey-ultralight;
  padding: 20px 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1ch;
}
.result-indication {
  font-weight: bold;
}
.step-content {
  display: flex;
  flex-direction: column;
  color: $secondary;
  gap: 1ch;
  margin-right: 5px;
}
</style>
