<script>
import InfoTooltip from '@comp/InfoTooltip.vue'

export default {
  name: 'IndicatorGroup',
  components: {
    InfoTooltip,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'fas fa-question',
    },
    indicators: {
      type: Array,
      required: true,
      default: () => [
        {
          value: true,
          label: 'example',
          name: 'indicator',
          tooltipText: 'This is an indicator',
        },
      ],
    },
  },
  computed: {
    indicatorsGroup () {
      if (this.indicators.every(indicator => indicator.value)) {
        return true
      } else if (this.indicators.every(indicator => !indicator.value)) {
        return false
      } else {
        return '?'
      }
    },
    indicatorsGroupIndeterminate () {
      return this.indicatorsGroup === '?'
    },
  },
  methods: {
    indicatorsGroupChanged () {
      if (this.indicatorsGroup && !this.indicatorsGroupIndeterminate) {
        this.indicators.forEach(indicator => {
          indicator.value = false
        })
      } else {
        this.indicators.forEach(indicator => {
          indicator.value = true
        })
      }
    },
  },
}
</script>
<template>
  <div class="indicator-group">
    <div class="indicator-icon">
      <v-icon size="large">
        {{ icon }}
      </v-icon>
    </div>
    <div class="indicator-title">
      {{ name }}
    </div>
    <div class="legend">
      <div class="indicators-column">
        <v-checkbox
          :model-value="indicatorsGroup"
          :indeterminate="indicatorsGroupIndeterminate"
          color="primary"
          density="compact"
          hide-details="true"
          @click="indicatorsGroupChanged"
        >
          <template v-slot:label>
            <span class="column-title">
              {{ $gettext('Indicators') }}
            </span>
          </template>
        </v-checkbox>
        <ul>
          <li
            v-for="(indicator) in indicators"
            :key="indicator.name"
            class="indicator-item"
          >
            <v-checkbox
              v-model="indicator.value"
              hide-details="true"
              density="compact"
              color="primary"
            >
              <template v-slot:label>
                <span>
                  {{ $gettext(indicator.label) }}
                  <InfoTooltip
                    tooltip-position="end"
                    max-width="300px"
                  >
                    {{ $gettext(indicator.tooltipText) }}
                  </InfoTooltip>
                </span>
              </template>
            </v-checkbox>
          </li>
        </ul>
      </div>
      <div class="horizons-column">
        <div class="column-title">
          {{ $gettext('Horizons') }}
        </div>
        <slot name="horizons" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
/* stylelint-disable-next-line selector-class-pattern */
:deep(.indicator-item > .v-input > .v-input__control > .v-selection-control) {
  align-items: flex-start;
}
.indicator-group {
  display: grid;
  grid-template-areas:
    "icon title"
    ". legend";
  gap: 0.5rem;
  grid-template-columns: 4ch auto;
}
.indicator-icon {
  grid-area: icon;
}
.indicator-title {
  grid-area: title;
  font-weight: bold;
  margin: auto 0;
}
.legend {
  grid-area: legend;
  display: grid;
  grid-template-areas:
    "column-title ."
    "indicators horizons";
  align-items: flex-start;
  justify-content: space-around;
}
.indicators-column {
  grid-area: indicators;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}
.horizons-column {
  grid-area: horizons;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 2px $secondary solid;
  padding-left: 10px;
}
.column-title {
  grid-area: column-title;
  font-weight: bold;
}
.indicator-item {
  display: flex;
}
:deep(.v-label) {
  margin-left: 0.5ch;
  gap: 0.5ch;
}
</style>
