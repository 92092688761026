<script>
import { createAnalysisRoute } from '@src/router'
export default {
  name: 'ResultsDashboardHeader',
  methods: {
    goToCreateAnalysisPage () {
      this.$router.push({ name: createAnalysisRoute.name })
    },
  },
}
</script>
<template>
  <section class="results-dashboard-header card">
    <h1 class="header-title">
      {{ $gettext('Results dashboard') }}
    </h1>
    <v-btn
      color="primary"
      prepend-icon="fas fa-search-location"
      @click="goToCreateAnalysisPage"
    >
      {{ $gettext('Create a new analysis') }}
    </v-btn>
  </section>
</template>
<style lang="scss" scoped>
.results-dashboard-header {
  flex-direction: row;
  padding: 20px;
  flex-grow: 0;
  flex-shrink: 0;
}
.header-title {
  flex-grow: 1;
  font-size: 1.5rem;
  font-weight: 500;
}
</style>
