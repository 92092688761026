import gql from 'graphql-tag'
import {
  userFragment,
} from '@src/fragments'

export const getAllUsersQuery = gql`
${userFragment}
query {
  allUsers {
    ...UserFragment
  }
}
`
export const allTasksQuery = gql`
  query {
    allTasks (
      order: {creationDatetime: DESC}
    ) {
      id
      creationDatetime
      expirationDatetime
      status
      pendingInfo
      resultUrl
    }
  }
`
export const getLineStringFromGeojson = gql`
  query getLineStringFromGeojson (
    $geojsonFile: Upload!
  ) {
    getLineStringFromGeojson(geojsonFile: $geojsonFile) {
      geojson
    }
  }
`
