import {
  transversal,
  sustainable,
  sds,
  alerts,
  getDefaultExport,
  getVuetifyExport,
} from './default-color-shades.js'

const app = {
  main: transversal.main,
  primary: sustainable.main,
  primary_light: sds.primary,
  primary_dark: sds.primary_dark,
  secondary: sustainable.d700,
  secondary_light: sustainable.d300,
  secondary_dark: sustainable.d300,
  success: alerts.success_green,
  warning: alerts.warning_orange,
  error: alerts.error_red,
}

export default getDefaultExport(app)
export const vuetifyColors = getVuetifyExport(app)
