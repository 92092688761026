<script>
export default {
  name: 'Step1ZoneContent',
  props: {
    selectedZoneIsIncorrect: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
<template>
  <section class="step-content">
    <div class="indications">
      <div>
        {{ $gettext('Draw on the map:') }}
      </div>
      <ol>
        <li>
          {{ $gettext('Click on the button') }}
          <v-icon>fas fa-vector-square</v-icon>
          {{ $gettext('on the map to activate the drawing tool') }}
        </li>
        <li>{{ $gettext('Click on the map to draw a polygon') }}</li>
        <li>{{ $gettext('Double-click on the map or press "Enter" to end your drawing') }}</li>
      </ol>
      <div class="result-indication">
        {{ $gettext('The bounding box of the polygon you drew will be used as an area of analysis') }}
      </div>
      <v-alert
        v-if="selectedZoneIsIncorrect"
        type="error"
        icon="fas fa-exclamation-triangle"
        rounded="lg"
      >
        {{ $gettext('The selected zone is too big because its height or/and width is more than 1000 km.') }}
        <br>
        {{ $gettext('Please edit and reduce your polygon to launch the analysis.') }}
      </v-alert>
    </div>
  </section>
</template>
