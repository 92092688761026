export const formatDateAndTime = function (rawDate) {
  const lang = this.$vuetify.locale.current.value
  const date = new Date(rawDate)
  return `${date.toLocaleDateString(lang)} - ${date.toLocaleTimeString(lang)}`
}

export const formatDate = function (rawDate) {
  const lang = this.$vuetify.locale.current.value
  const date = new Date(rawDate)
  return date.toLocaleDateString(lang)
}
